import { Image } from "@mybridge/ui/image";
import { Box } from "@mybridge/ui/layout";
import { VideoPlayer } from "@mybridge/ui/video-player";
import { Skeleton, SkeletonCircle, SkeletonText } from "@mybridge/ui/skeleton";
import { useContext, useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import { PostCommentRowContext } from "../context";
import styles from "./media-player.module.scss";
export const MediaDisplay = ({ onLoaded }) => {
    const { comment } = useContext(PostCommentRowContext);
    const { gif, media } = comment ?? {};

    const [images_, setImages_] = useState([])
    const [videos_, setVideos_] = useState([])
    const [gifs_, setGifs_] = useState([])

    useEffect(() => {
        prepareMedia()
    }, [media])

    useEffect(() => {
        prepareGifs()
    }, [gif])

    const prepareMedia = async () => {
        const vids = []
        const imgs = []
        for (let m of media ?? []) {
            const { file } = m ?? {}
            if (
                file?.toLowerCase?.()?.includes?.(".mp4") ||
                file?.toLowerCase?.()?.includes?.(".mov")
            ) {
                vids.push(file)
            } else {
                imgs.push(file)
            }
        }
        setVideos_(vids)
        setImages_(imgs)
    }

    const prepareGifs = async () => {
        const gs = []
        if (gif?.length) {
            gs.push(gif)
        }
        setGifs_(gs)
    }

    const handleLoading = (val) => {
        onLoaded?.()
    }

    return <>

        {videos_?.length ?
            <Box
                pos="relative"
                overflow="hidden"
                className={styles.mediaPlayer}
            >
                <VideoPlayer
                    wrapperProps={{ minHeight: "" }}
                    onLoad={() => handleLoading(false)}
                    src={videos_?.[0]}
                    height="100%"
                    width="100%"
                    style={{ backgroundColor: "black", maxHeight: "70vh", width: "100%", height: "100%" }}
                    className="player"
                />
            </Box> : <>
                {images_?.length ?
                    images_.length > 1 ?
                        <>
                            <Masonry>
                                {images_?.map?.((img, index) =>
                                    <Image onLoad={() => handleLoading(false)} src={img} key={index} />
                                )}
                            </Masonry>
                        </>
                        :
                        <>
                            <Image
                                onLoad={() => handleLoading(false)}
                                maxH="50vh"
                                src={images_?.[0]}
                            />
                        </>
                    : <>
                        {gifs_?.length ?
                            <Box pos="relative">
                                <Image
                                    onLoad={() => handleLoading(false)}
                                    maxH="50vh"
                                    src={gifs_?.[0]}
                                />
                            </Box>
                            : ""}
                    </>}
            </>
        }
    </>
}